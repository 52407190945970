import React from 'react';
import GoogleMapComponent from './GoogleMapComponent';
import 'leaflet/dist/leaflet.css';
import { size } from 'lodash';

import { Button, Col, Form, Row } from "react-bootstrap";

const audioFile = require('../src/audios/Y2meta.app - Michael Bublé - Feeling Good [Official 4K Remastered Music Video] (128 kbps).mp3');

const Footer=() => {

    return(

        <footer id="contacto">
        <div className="footer-bg">
          <h2>CONTÁCTANOS</h2>
          <Form>

         
            <Row className="mb-2">
              
              <Form.Group as={Col} md="6">
              <Form.Label className='concepto2'>Nombre Completo</Form.Label>
              <Form.Control
                  name="nombrecompleto"
                  type="text"
                  placeholder="Nombre Completo*"
                  
                />
              
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label className='concepto2'>D.N.I.</Form.Label>
                <Form.Control
                  name="dni"
                  type="number"
                  placeholder="DNI*"
                  
                />
              </Form.Group>

<p></p>
              <Form.Group as={Col} md="6">
                <Form.Label className='concepto2'>Celular</Form.Label>
                <Form.Control
                  name="celular"
                  type="number"
                  placeholder="Celular*"
                  
                />
                </Form.Group>

                <Form.Group as={Col} md="6">
                <Form.Label className='concepto2'>Correo Electrónico</Form.Label>
                <Form.Control
                  name="correo"
                  type="email"
                  placeholder="Correo Electrónico*"
                  
                />
              </Form.Group>

              </Row>
        <br />
        <Form.Group as={Col} md="20">
          <Form.Label className='concepto2'>Comentarios</Form.Label>
          <Form.Control
            as="textarea"
            name="comentarios"
            placeholder="Cuéntanos más aquí..."
            rows={7}
           
          />
        </Form.Group>
             
          
            
            <input type="submit" value="Enviar" className="btn-1" />
          </Form>
              <div className="MapContainer">
                    <GoogleMapComponent />
              </div>
        </div>
     

        <div className="footer-txt">
          <p>
            Derechos Reservados 2024@
            <a href="#">Volver al inicio</a>
          </p>
        </div>
        <div className="audio-container">
          <audio controls>
            <source src={audioFile} type="audio/mpeg" />
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      </footer>

    )

}
export default Footer;
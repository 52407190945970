import React, { useState } from 'react';
import { Button, Col, Form, Row, Table } from "react-bootstrap";




const ReservationSystem = () => {
  const initialState = {
    nombrecompleto: "",
    dni: "",
    correo: "",
    celular: "",
    distrito: "0",
    fecha: "",
    tamaño: "0",
    añofabricacion: "",
    tipoServicio: "0",
    modelo:"",
    marca: "",
    comentarios: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [reservas, setReservas] = useState([
    // Datos de ejemplo
    { dni: '74815737', nombrecompleto: 'Juan Pérez', correo: 'juan.perez@gmail.com', celular: '958243214', fecha: '2024-08-31T14:00', distrito: 'Los Olivos', tamaño: 'M', marca: 'Toyota', modelo: 'Corolla', tipoServicio: 'Limpieza Interior', comentarios: 'Ninguno' }
    ,{ dni: '74815737', nombrecompleto: 'Juan Pérez', correo: 'juan.perez@gmail.com', celular: '958243214', fecha: '2024-08-01T09:00', distrito: 'Los Olivos', tamaño: 'M', marca: 'Toyota', modelo: 'Corolla', tipoServicio: 'Limpieza Exterior', comentarios: 'Ninguno' }
    ,{ dni: '64810037', nombrecompleto: 'Maria Suarez', correo: 'maria.suarez@hotmail.com', celular: '935688729', fecha: '2024-08-01T10:00', distrito: 'Independencia', tamaño: 'M', marca: 'Changan', modelo: 'New CS15', tipoServicio: 'Servicio Basico', comentarios: 'Ninguno' }
    ,{ dni: '28295212', nombrecompleto: 'Lucho Salvador', correo: 'lucho.salvador@gmail.com', celular: '985674114', fecha: '2024-08-02T16:00', distrito: 'San Isidro', tamaño: 'S', marca: 'Nissan', modelo: 'V-Drive', tipoServicio: 'Limpieza Exterior', comentarios: 'Ninguno' }
    ,{ dni: '28295212', nombrecompleto: 'Lucho Salvador', correo: 'lucho.salvador@gmail.com', celular: '985674114', fecha: '2024-08-03T1:00', distrito: 'San Isidro', tamaño: 'S', marca: 'Nissan', modelo: 'V-Drive', tipoServicio: 'Limpieza Exterior', comentarios: 'Ninguno' }
    // Añade más reservas según sea necesario
  ]);
  const [dniQuery, setDniQuery] = useState('');
  const [filteredReservas, setFilteredReservas] = useState([]);
  const [showTable, setShowTable] = useState(false); // Estado para controlar la visibilidad de la tabla

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos
    if (
      formData.nombrecompleto === "" ||
      formData.dni === "" ||
      formData.correo === "" ||
      formData.celular === "" ||
      formData.distrito === "0" ||
      formData.fecha === "" ||
      formData.tamaño === "0" ||
      formData.modelo === "" ||
      formData.tipoServicio === "0" ||
      formData.marca === ""
    ) {
      alert("Por favor, complete todos los campos obligatorios.");
    } else {
      setReservas([...reservas, formData]); // Agregar nueva reserva
      setFormData(initialState); // Resetear formulario
    }
  };

  const handleReset = () => {
    setFormData(initialState);
  };

  const handleDniChange = (e) => {
    setDniQuery(e.target.value);
  };

  const handleQuery = () => {
    const filtered = reservas.filter(reserva => reserva.dni === dniQuery);
    setFilteredReservas(filtered);
    setShowTable(true); // Mostrar la tabla después de buscar
  };

  const handleClearDni = () => {
    setDniQuery('');
    setFilteredReservas([]);
    setShowTable(false); // Ocultar la tabla si el campo DNI está vacío
  };

  return (
    <section id='reservas' className="prices">
      <div className="prices-1">
        <h2>Reserva</h2>
        <p>
          Estimado cliente, por favor ingrese los datos de usted y su vehiculo, si hubiera alguna observacion indicar en <b >comentario</b>.
        </p>
        <fieldset>
          <legend className='datos'>Datos del Cliente</legend>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2">
              {/* Campos del formulario */}
              <Form.Group as={Col} md="6">
              <Form.Label className='concepto'>Nombre Completo</Form.Label>
              <Form.Control
                  name="nombrecompleto"
                  type="text"
                  placeholder="Nombre Completo*"
                  value={formData.nombrecompleto}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label className='concepto'>D.N.I.</Form.Label>
                <Form.Control
                  name="dni"
                  type="number"
                  placeholder="DNI*"
                  value={formData.dni}
                  onChange={handleInputChange}
                />
              </Form.Group>
             
              <p></p>
              <Form.Group as={Col} md="6">
                <Form.Label className='concepto'>Correo Electrónico</Form.Label>
                <Form.Control
                  name="correo"
                  type="email"
                  placeholder="Correo Electrónico*"
                  value={formData.correo}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label className='concepto'>Celular</Form.Label>
                <Form.Control
                  name="celular"
                  type="number"
                  placeholder="Celular*"
                  value={formData.celular}
                  onChange={handleInputChange}
                />
                </Form.Group>
              

              <p></p>
              <Form.Group as={Col} md="6">
                <Form.Label className='concepto'>Distrito</Form.Label>
                <Form.Select name="distrito" value={formData.distrito} onChange={handleInputChange}>
                  <option value="0">Seleccionar distrito...</option>
                  <option value="Ancón">Ancón</option>
                  <option value="Ate">Ate</option>
                  <option value="Barranco">Barranco</option>
                  <option value="Breña">Breña</option>
                  <option value="Carabayllo">Carabayllo</option>
                  <option value="Comas">Comas</option>
                  <option value="El Agustino">El Agustino</option>
                  <option value="Los Olivos">Los Olivos</option>
                  <option value="La Victoria">La Victoria</option>
                  <option value="Independencia">Independencia</option>
                  <option value="Puente Piedra">Puente Piedra</option>
                  <option value="Rimac">Rimac</option>
                  <option value="San Borja">San Borja</option>
                  <option value="San Isidro">San Isidro</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label className='concepto'>Fecha y Hora</Form.Label>
                <Form.Control
                  name="fecha"
                  type="datetime-local"
                  placeholder="Fecha y Hora*"
                  value={formData.fecha}
                  onChange={handleInputChange}
                />
                </Form.Group>
             
              
              <br /><br /><br /><br /><br />

              <legend className='datos'>Datos del Vehículo</legend>

      <Form.Group as={Col} md="6">
      <Form.Label className='concepto'>Tamaño del Carro</Form.Label>
      <Form.Select name="tamaño" value={formData.tamaño} onChange={handleInputChange}>
        <option value="0">Selecciona Tamaño</option>
        <option value="S">S</option>
        <option value="M">M</option>
        <option value="L">L</option>
        <option value="XL">XL</option>
      </Form.Select>
      </Form.Group>

      <Form.Group as={Col} md="6">
        <Form.Label className='concepto'>Modelo</Form.Label>
        <Form.Control
          name="modelo"
          type="text"
          placeholder="Modelo"
          value={formData.modelo}
          onChange={handleInputChange}
        />
      </Form.Group>
      <p></p>
      <Form.Group as={Col} md="6">
        <Form.Label className='concepto'>Tipo de Servicio</Form.Label>
        <Form.Select name="tipoServicio" value={formData.tipoServicio} onChange={handleInputChange}>
          <option value="0">Tipo de Servicio</option>
          <option value="Servicios Básicos">Servicios Básicos</option>
          <option value="Limpieza Interior">Limpieza Interior</option>
          <option value="Limpieza Exterior">Limpieza Exterior</option>
          <option value="Paquetes de Limpieza">Paquetes de Limpieza</option>
        </Form.Select>
      </Form.Group>

      <Form.Group as={Col} md="6">
        <Form.Label className='concepto'>Marca</Form.Label>
        <br />
        <Form.Control
          name="marca"
          type="text"
          placeholder="Marca"
          value={formData.marca}
          onChange={handleInputChange}
        />
      </Form.Group>

        </Row>
        <br />
        <Form.Group as={Col} md="20">
          <Form.Label className='concepto'>Comentarios</Form.Label>
          <Form.Control
            as="textarea"
            name="comentarios"
            placeholder="Cuéntanos más aquí..."
            rows={7}
            value={formData.comentarios}
            onChange={handleInputChange}
          />
        </Form.Group>

      <Form.Group>
        <center>
          <Button type="submit" style={{ margin: "20px" }}>Enviar</Button>
          <Button type="button" variant="warning" onClick={handleReset}>Borrar</Button>
          </center>
          </Form.Group>
            
      </Form>
        </fieldset>
        <br />
        <a href="#" className="btn-1">Ir a Inicio</a>
      </div>

      <div className="prices-2">
        <Row>
        
        <h2> Consulta tu Reserva</h2>
          <Col className="mb-2">
          <center>
            <Form.Group as={Col} md="3">
              <Form.Label className='busdni'>Búsqueda por DNI</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Ingrese DNI para buscar"
                value={dniQuery}
                onChange={handleDniChange}
              />
              
            </Form.Group>
            </center>
            <br />
            <center>
            
  <Button onClick={handleQuery} variant="primary" style={{ marginLeft: '1rem' }}>Buscar</Button>
  <Button onClick={handleClearDni} variant="secondary" style={{ marginLeft: '1rem' }}>Limpiar</Button>
              </center>
          </Col>
        </Row>

        {showTable && (
  <Table class="table">
    <thead >
      <tr className="tatra">
        <th scope="col">Cliente </th>
        <th scope="col">Celular</th>
        <th scope="col">Fecha y Hora</th>
        <th scope="col">Tamaño</th>
        <th scope="col">Modelo</th>
        <th scope="col">Marca</th>
        <th scope="col">Servicio</th>
        <th scope="col">Comentario</th>
      </tr>
    </thead>
    <tbody>
      {filteredReservas.length > 0 ? (
        filteredReservas.map((reserva, index) => (
          <tr key={index} className="tatrd">
            <td>{reserva.nombrecompleto}</td>
            <td>{reserva.celular}</td>
            <td>{reserva.fecha}</td>
            <td>{reserva.tamaño}</td>
            <td>{reserva.modelo}</td>
            <td>{reserva.marca}</td>
            <td>{reserva.tipoServicio}</td>
            <td>{reserva.comentarios}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="11" style={{ textAlign: 'center' }}>No se encontraron reservas para el DNI ingresado.</td>
        </tr>
      )}
    </tbody>
  </Table>
)}


      </div>

    
    </section>
  );
};

export default ReservationSystem;

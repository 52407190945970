import '../src/Css/style.css';
import React, { useState } from 'react';


const menuIcon = require('../src/imagenes/menu.png');
const serviceImage1 = require('../src/imagenes/logo.png');
 
const Hearder=() => {

    const [hovered, setHovered] = useState(false); 


    return(
        <header className={`header ${hovered ? 'hovered' : ''}`}
                onMouseEnter={() => setHovered(true)}  
                onMouseLeave={() => setHovered(false)}>

        <div className="menu container">
        <img src={serviceImage1}  className="menu-logo" alt="Logo" />
          <input type="checkbox" id="menu" />
          <label htmlFor="menu">
            <img src={menuIcon} className="menu-icono" alt="Menu icono" />
          </label>
          <nav className="navbar">
            <ul>
              <li><a href="#inicio">Inicio</a></li>
              <li><a href="#servicios">Servicio</a></li>
              <li><a href="#reservas">Reserva</a></li>
              <li><a href="#idSection3">Profesionales</a></li>
              
              <li><a href="#contacto">Contacto</a></li>
            </ul>
          </nav>
        </div>
        <div className="header-content container">
          <div className="header-txt">
            <br />
            <br />
            <p></p>
            <h1>CARS WASH</h1>
            <span>ECOWASH</span>
            <p>
            Somos un centro especializado en limpieza y detallado de vehículos con 10 años 
            de experiencia en el mercado local. Nuestro equipo está conformado por jóvenes 
            profesionales y especialistas de 1er nivel en el cuidado integral de vehículos.
            </p>
            <a href="#" className="btn-1">Información</a>
          </div>
          <div className="header-dir">
            <div className="dir">
              <h3>Dirección</h3>
              <p>Av. Ayacucho cuadra 4  Surco, Lima - Perú</p>
            </div>
            <div className="dir">
              <h3>Telefono</h3>
              <p>(51) 956 770 355</p>
            </div>
            <div className="dir">
              <h3>Horario</h3>
              <p>Lun - Sáb: de 9:00 a 18:00</p>
            </div>
          </div>
        </div>
      </header>
    );
  }
  
export default Hearder;
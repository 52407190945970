import React from 'react';
import '../src/Css/style.css';
import './Section2.jsx';

const serviceImage1 = require('../src/imagenes/servicio1.png');
const serviceImage2 = require('../src/imagenes/servicio2.png');
const serviceImage3 = require('../src/imagenes/servicio3.png');
const serviceImage4 = require('../src/imagenes/servicio4.png');

const Main=() => {

    return(


<main id="servicios" className="services container">
      <div className="services-txt">
        <h2>Nuestros Servicios</h2>
        <hr />
        <p>
        Consolidarnos como el centro especializado líder en la limpieza y cuidado de vehículos, promoviendo el desarrollo del detailing en nuestro país.

          </p>
      </div>
      <div className="services-group">
        <div className="services-1">
          <img src={serviceImage1} alt="Icono del Servicio 1" />
          
          <h4>Servicios Básicos</h4>
          <p>
          Servicios de limpieza frecuentes que se realizan en un menor tiempo. Son servicios de mantenimiento que no incluyen limpiezas profundas. Recomendado 1 vez por semana o cada 10 días.
          </p>
          <a href="#reservas"> → Solicítalo aquí </a>
        </div>
        <div className="services-1">
          <img src={serviceImage2} alt="Icono del Servicio 2" />
          
          <h4>Limpieza Interior</h4>
          
           <p> Abarca la limpieza de zonas interiores del vehículo, recomendado para clientes que no deseen hacer un servicio de salón completo pueden optar por la limpieza de zonas puntuales donde se acumuló la suciedad. Usamos productos de limpieza como APC (all purpose cleaner), desengrasantes, limpia tapiz, entre otros y una aspiradora multiuso de polvo y líquidos la cual permite reducir el tiempo de secado de las superficies recién limpiadas.
           </p>
          <a href="#prices">→ Solicítalo aquí </a>
        </div>
        <div className="services-1">
          <img src={serviceImage3} alt="Icono del Servicio 3" />
          
          <h4>Limpieza Exterior</h4>
          <p>
          Es la limpieza de zonas exteriores del vehículo como motor y chasis (parte baja), aquí tenemos todos los cuidados necesarios ya que el motor es una zona delicada, se cubren con paños y plásticos las zonas sensibles como computadora, cajas de fusible, bobinas de encendido y radiador. Para el lavado de chasis, el vehículo sube a una rampa para poder realizar la limpieza a detalle haciendo uso de agua a presión y desengrasante.
            </p>
          <a href="#prices">→ Solicítalo aquí </a>
        </div>
        <div className="services-1">
          <img src={serviceImage4} alt="Icono del Servicio 4" />
          
          <h4>Paquetes de Limpieza</h4>
          <p>
          Son servicios que incluyen varias zonas del vehículo dependiendo de la necesidad del cliente. Recomendado para vehículos que han acumulado suciedad a través del tiempo y necesitan de una limpieza a profundidad y al detalle. El servicio más completo de interiores es el lavado de salón y se recomienda realizarlo como mínimo 1 vez al año.
          </p>
          <a href="#prices">→ Solicítalo aquí </a>
        </div>
      </div>
      <a href="#" className="btn-1">Ir a Inicio</a>
    </main>
        )

    }
    export default Main;
import React from 'react';
import Carrousel from './carrousel/Carrousel';

const tra1 = require('../src/imagenes/b1.jpg');
const tra2 = require('../src/imagenes/b6.png');
const tra3 = require('../src/imagenes/b4.jpg');
const facebookIcon = require('../src/imagenes/facebook.png');
const tiktokIcon = require('../src/imagenes/tik-tok.png');
const whatsappIcon = require('../src/imagenes/whatsapp.png');
const instagramIcon = require('../src/imagenes/instagram.png');



const Section3=() => {

    return(
        <section id="idSection3" className="personal container">
      <div className="personal-txt">
      <br />
        <br />
        <h2>Nuestros Profesionales</h2>
        <hr />
        <p>
        Somos un centro especializado en limpieza y detallado de vehículos con 10 años de experiencia en el mercado local. Nuestro equipo está conformado por jóvenes profesionales y especialistas de 1er nivel en el cuidado integral de vehículos
          </p>
      </div>
      <div className="personal-group">
        <br />
        <Carrousel></Carrousel>
        <br /><br /><br /><br />
        <div className="personal-1">
          <img src={tra1} alt="Trabajador Francisco Diaz Flores" />
          <h3>Francisco Diaz Flores</h3>
          <p>
          El objetivo es que cada visita no solo deje el automóvil con un aspecto rejuvenecido, sino que también mejore la experiencia de conducción</p>
          <a href="#"></a>
        </div>
        <div className="personal-1">
          <img src={tra2} alt="Trabajador Eduardo Poma Torres" />
          <h3>Eduardo Poma Torres</h3>
          <p>
          El enfoque va más allá de la limpieza exterior, buscando renovar cada aspecto del vehículo, tanto por fuera como por dentro</p>
          <a href="#"></a>
        </div>
        <div className="personal-1">
          <img src={tra3} alt="Trabajador Julian Gonzales Suarez" />
          <h3>Julian Gonzales Suarez</h3>
          <p>
          La innovación en el cuidado automotriz se combina con la pasión por mantener los vehículos en su mejor estado</p>
          <a href="#"></a>
        </div>
        
      </div>
      <a href="#" className="btn-1">Ir a Inicio</a>
      <div className="social-icons">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt="Facebook" />
        </a>
        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
          <img src={tiktokIcon} alt="TikTok" />
        </a>
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <img src={whatsappIcon} alt="WhatsApp" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" />
        </a>
      </div>
    </section>
        

    )

}
export default Section3;
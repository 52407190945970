import { useEffect } from "react";
import './carrousel.css';
const empl1 = require('../imagenes/b3.jpg');
const empl2 = require('../imagenes/b9.jpg');
const empl3 = require('../imagenes/b8.jpg');
const empl4 = require('../imagenes/b-1.jpg');
const empl5 = require('../imagenes/b2.jpg');
const empl6 = require('../imagenes/b5.jpg');
const empl7 = require('../imagenes/b7.jpg');

function Carrousel() {

    useEffect(() => {

        const carrusel = document.querySelector(".carrusel-items");

        let maxScrollLeft = carrusel.scrollWidth - carrusel.clientWidth;
        let intervalo = null;
        let step = 1;
        const start = () => {
            intervalo = setInterval(function () {
                carrusel.scrollLeft = carrusel.scrollLeft + step;
                if (carrusel.scrollLeft === maxScrollLeft) {
                    step = step * -1;
                } else if (carrusel.scrollLeft === 0) {
                    step = step * -1;
                }
            }, 10);
        };

        const stop = () => {
            clearInterval(intervalo);
        };

        // carrusel.addEventListener("mouseover", () => {
        //     stop();
        // });

        // carrusel.addEventListener("mouseout", () => {
        //     start();
        // });
        // start();
    })
 
    return (
        <div className="carrusel">
            <div className="carrusel-items">
                <div class="carrusel-item">
                    <img src={empl1} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl2} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl3} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl4} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl5} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl6} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl7} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl1} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl2} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl3} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl4} alt="" />
                </div>
                <div className="carrusel-item">
                    <img src={empl5} alt="" />
                </div>
            </div>
        </div>
    )

}

export default Carrousel;